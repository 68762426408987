<template>
  <div class="fr-event-photo" :style="imgStyle">
    <div ref="photo" class="photo">
      <img v-lazy="photoObj" ref="imgPhoto">
    </div>
    <div ref="photo" class="photo">
      <img v-lazy="featurePhotoObj" ref="imgFeature">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FrEventPhoto',
  props: {
    event: {
      type: Object,
      required: true
    },
    zoomFactor: {
      type: Number,
      default: 1.0
    }
  },
  data() {
    return {
      defaultUnknow: require('@/assets/icons/unknow-user.svg')
    }
  },
  computed: {
    ...mapState('historyFr', ['rank']),
    selectedEvent() {
      return this.event.triggered.find(item => item.rank === this.rank)
    },
    imgStyle() {
      return {
        '--imgSize': `${190 * this.zoomFactor}px`
      }
    },
    photoObj() {
      return {
        src: this.event.photoUrl,
        error: this.defaultUnknow,
      }
    },
    featurePhotoObj() {
      return {
        src: this.selectedEvent?.featurePhotoUrl,
        error: this.defaultUnknow,
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scaleImage()
    })
    window.addEventListener('resize', this.scaleImage)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.scaleImage)
  },
  methods: {
    scaleImage() {
      // html2canvas 不支援object-fit: cover，所以要自己另外處理
      // 計算影像維持原長寬比例，以相對最小邊的比例來填滿div，裁切超出的部分
      let divWidth = this.$refs.photo.offsetWidth
      let divHeight = this.$refs.photo.offsetHeight

      let image = new Image()
      image.src = this.event.photoUrl
      image.onload = () => {
        let imgNaturalWidth = image.naturalWidth
        let imgNaturalHeight = image.naturalHeight
        let fitScale = Math.max(divWidth / imgNaturalWidth, divHeight / imgNaturalHeight)
        this.$refs.imgPhoto.style.width = imgNaturalWidth * fitScale + 'px'
        this.$refs.imgPhoto.style.height = imgNaturalHeight * fitScale + 'px'
      }

      let featureImage = new Image()
      featureImage.src = this.selectedEvent?.featurePhotoUrl
      featureImage.onload = () => {
        let imgNaturalWidth = featureImage.naturalWidth
        let imgNaturalHeight = featureImage.naturalHeight
        let fitScale = Math.max(divWidth / imgNaturalWidth, divHeight / imgNaturalHeight)
        this.$refs.imgFeature.style.width = imgNaturalWidth * fitScale + 'px'
        this.$refs.imgFeature.style.height = imgNaturalHeight * fitScale + 'px'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.fr-event-photo {
  width: 100%;
  display: flex;
  justify-content: space-around;
  column-gap: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff80;

  .photo {
    // width: var(--imgSize);
    // height: var(--imgSize);
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF1a;
    border-radius: 8px;
    // margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
    img[lazy=error] {
      width: 60%;
      height: 60%;
    }
  }
}
</style>